[data-v-3c8fc852] .home .avue-crud .el-table {
  height: calc(100vh - 248px) !important;
  max-height: calc(100vh - 248px) !important;
}
.homeTopTabs[data-v-3c8fc852] {
  width: calc(100% - 24px);
  padding: 0 12px;
  background-color: #FFF;
}
.homeTopTabs[data-v-3c8fc852] .el-tabs__header {
  margin: 0;
}